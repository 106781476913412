<template>
    <div class="minha-aplicacao">
        <h1 class="fonte-primaria negrito grande">{{ momentoAtualDoDia }},</h1>
        <h1 class="fonte-primaria negrito grande">{{ nomeUsuario }}</h1>

        <h3 class="fonte-primaria negrito titulo confira-aplicacoes">Confira suas aplicações abaixo</h3>
        <CampoPesquisa v-model="campoPesquisa" @pesquisar="pesquisar()" :class="`barra-pesquisa-${tamanhoTela}`" />

        <unicom-linha justificarItem="between" style="padding-left: 15px" class="buscando">
            <unicom-coluna md="10">
                <p class="fonte-secundaria negrito" v-if="loading && campoPesquisa">
                    Buscando por {{ campoPesquisa }}...
                </p>
            </unicom-coluna>

            <unicom-coluna style="margin-left: auto" lg="2" md="3" v-if="windowSize > 425">
                <div class="display-inline" @click="tipoDeCartao = 1">
                    <unicom-icone
                        nome="grid"
                        :tamanho="33"
                        class="icone-tipo-cartao"
                        textoComIcone
                        style="margin-left: 25px"
                        :class="tipoDeCartao === 1 ? 'selecionado' : ''"
                    />
                </div>

                <div class="display-inline" @click="tipoDeCartao = 2">
                    <unicom-icone
                        nome="list"
                        :tamanho="33"
                        class="icone-tipo-cartao"
                        textoComIcone
                        :class="tipoDeCartao === 2 ? 'selecionado' : ''"
                    />
                </div>
            </unicom-coluna>
        </unicom-linha>

        <div v-if="tipoDeCartao === 1">
            <ListagemAplicacoes
                :aplicacoes="ultimosAcessos"
                :qtResultados="ultimosAcessos.length"
                :todasAplicacoes="false"
                @acessar-aplicacao="value => acessarAplicacao(value)"
            />
        </div>

        <div v-if="tipoDeCartao === 2">
            <ListagemAplicacoesPorLinha
                :aplicacoes="ultimosAcessos"
                :qtResultados="ultimosAcessos.length"
                :todasAplicacoes="false"
                @acessar-aplicacao="value => acessarAplicacao(value)"
            />
        </div>

        <div v-if="tipoDeCartao === 1">
            <ListagemAplicacoes
                :aplicacoes="minhasAplicacoes"
                :qtPaginas="qtPaginas"
                :pagina="pagina"
                :qtResultados="qtResultados"
                :todasAplicacoes="true"
                @troca-pagina="pagina => trocaPagina(pagina)"
                @acessar-aplicacao="value => acessarAplicacao(value)"
            />
        </div>

        <div v-if="tipoDeCartao === 2">
            <ListagemAplicacoesPorLinha
                :aplicacoes="minhasAplicacoes"
                :qtPaginas="qtPaginas"
                :pagina="pagina"
                :qtResultados="qtResultados"
                :todasAplicacoes="true"
                @troca-pagina="pagina => trocaPagina(pagina)"
                @acessar-aplicacao="value => acessarAplicacao(value)"
            />
        </div>

        <unicom-modal-loading :value="loading" />
    </div>
</template>

<script>
import AplicacoesServiceMixin from "./shared/services/AplicacoesServiceMixin";
import Redirect from "@/services/redirect/Redirect";

import CampoPesquisa from "./shared/CampoPesquisaAplicacao";
import ListagemAplicacoes from "./shared/ListagemAplicacoes";
import ListagemAplicacoesPorLinha from "./shared/ListagemAplicacoesPorLinha";

export default {
    mixins: [AplicacoesServiceMixin],

    components: {
        CampoPesquisa,
        ListagemAplicacoesPorLinha,
        ListagemAplicacoes
    },

    data() {
        return {
            windowSize: 0,
            nomeUsuario: "",
            campoPesquisa: "",
            tipoDeCartao: 1,
            loading: false,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            minhasAplicacoes: [],
            ultimosAcessos: []
        };
    },

    created() {
        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },

    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },

    async mounted() {
        this.nomeUsuario = this.getSession().nomeUsuario;
        this.getUltimosAcessos();
        this.getTodasAplicacoes();
    },

    methods: {
        redimensionar() {
            this.windowSize = window.innerWidth;
            if (this.windowSize <= 768) {
                this.tipoDeCartao = 2;
            } else {
                this.tipoDeCartao = 1;
            }
        },
        pesquisar() {
            this.pagina = 1;
            this.getTodasAplicacoes();
        },
        getTodasAplicacoes() {
            this.$store.commit("setLoading", true);
            this.getExcetoUltimasAcessadas(this.getArgs())
                .then(response => {
                    this.montaTabelaTodasAplicacoes(response.data);
                    this.$store.commit("setLoading", false);
                })
                .finally(() => {
                    this.$store.commit("setLoading", false);
                });
        },
        montaTabelaTodasAplicacoes(data) {
            this.minhasAplicacoes = [];
            this.minhasAplicacoes = data.conteudo;
            this.pagina = data.pagina;
            this.qtPaginas = data.qtPaginas;
            this.qtResultados = data.qtResultados;
        },
        getArgs() {
            return {
                direcaoOrdenacao: "asc",
                ordenacao: "",
                pagina: this.pagina,
                tamanhoPagina: "6",
                codOrDescTipoAuditoria: "1",
                nome: this.campoPesquisa
            };
        },
        trocaPagina(pagina) {
            this.pagina = pagina;
            this.getTodasAplicacoes();
        },
        getUltimosAcessos() {
            this.getUltimasAcessadas().then(response => {
                this.montaTabelaUltimosAcessos(response.data);
            });
        },
        montaTabelaUltimosAcessos(data) {
            this.ultimosAcessos = [];
            this.ultimosAcessos = data;
        },
        async acessarAplicacao(value) {
            this.loading = true;

            const args = this.montaArgsRedirect(value);
            const redirect = new Redirect(this.$appConfig.config.API);

            const idUnimed = this.getSession().usuarioUnimed.unimed.id;

            redirect
                .fetchRedirect(args, idUnimed)
                .then(response => {
                    window.open(response.data);
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        montaArgsRedirect(value) {
            let arg1 = "response_type=code";
            let arg2 = `client_id=${value.clientId}`;
            let arg3 = `client_secret=${value.clientSecret}`;
            let arg4 = `redirect_uri=${value.url}`;

            return `${arg1}&${arg2}&${arg3}&${arg4}`;
        }
    },

    computed: {
        momentoAtualDoDia() {
            var today = new Date();
            var curHr = today.getHours();

            if (curHr < 12) {
                return "Bom dia";
            } else if (curHr < 18) {
                return "Boa tarde";
            } else {
                return "Boa noite";
            }
        },
        tamanhoTela() {
            if (this.windowSize < 475) {
                return "mobile";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.minha-aplicacao {
    padding: 60px;

    & h1,
    h3 {
        color: #00995d;
        line-height: 35px;
    }

    & p {
        color: #60656c;
        font-size: 18px;
        display: inline-block;
    }

    & .icone {
        color: #60656c;
        margin-left: 6px;
    }

    & .texto-ultimo-acesso {
        margin-left: 8px;
    }

    & .barra-pesquisa-mobile {
        margin: 12px 0;
    }

    & .buscando {
        margin-top: 15px;
    }

    & .icone-tipo-cartao {
        margin-left: 12px;
        color: #9e9e9e;
        cursor: pointer;
    }

    & .selecionado {
        color: #00995d;
    }

    & .confira-aplicacoes {
        margin-top: 60px;
    }

    & .display-inline {
        display: inline-block;
    }

    & .aplicacoes {
        margin-top: 25px;
    }
}

@media only screen and (max-width: 425px) {
    .minha-aplicacao {
        padding: 30px;
    }
}
</style>
