<template>
    <div>
        <Aplicacoes />
    </div>
</template>

<script>
import Aplicacoes from "../components/aplicacoes/Aplicacoes.vue";
export default {
    components: {
        Aplicacoes
    }
};
</script>

<style scoped></style>
