<template>
    <unicom-cartao class="cartao-aplicacao">
        <div slot="header">
            <h2 class="fonte-secundaria negrito tituloCartao">{{ nomeDaAplicacao }}</h2>
        </div>

        <div slot="body" class="corpo">
            <p class="fonte-secundaria descricao">{{ descricao }}</p>
        </div>

        <div slot="footer">
            <unicom-linha justificarItem="between">
                <unicom-coluna md="6">
                    <unicom-botao :bloco="true" :altura="30" @click="$emit('acessar')">ACESSAR</unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-cartao>
</template>

<script>
export default {
    props: {
        nomeDaAplicacao: {
            type: String,
            required: true
        },

        descricao: {
            type: String,
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-aplicacao {
    width: 31%;
    margin-right: 15px;
    margin-top: 15px;

    & .tituloCartao {
        color: #60656c;
    }

    & .descricao {
        color: #9e9e9e;
    }
    & .corpo {
        min-height: 40px;
    }
}
</style>
