<template>
    <div>
        <div class="barra-pesquisa">
            <div class="antes-input">
                <slot name="antes">
                    <div @click="$emit('pesquisar')">
                        <unicom-icone nome="search" :tamanho="22" class="search" />
                    </div>
                </slot>
            </div>
            <input
                :style="{ 'padding-left': this.distanciaIconeTexto + 'px' }"
                class="campo-texto fonte-secundaria texto"
                ref="searchInput"
                :name="nameInput"
                :value="campo"
                :placeholder="placeholder"
                @input="atualizarValor"
                @keyup.enter="$emit('pesquisar')"
            />
            <slot name="depois">
                <div v-if="mostrarIconeExcluir" @click="limparCampo">
                    <unicom-icone nome="x" :tamanho="22" class="excluir" />
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ""
        },
        nameInput: {
            type: String
        },
        distanciaIconeTexto: {
            type: [String, Number],
            default: 50
        }
    },
    data() {
        return {
            campo: this.value
        };
    },
    watch: {
        value() {
            this.campo = this.value;
        }
    },
    methods: {
        atualizarValor() {
            this.campo = this.$refs.searchInput.value;
            this.$emit("input", this.campo);
        },
        limparCampo() {
            this.campo = "";
        }
    },

    computed: {
        mostrarIconeExcluir() {
            return this.campo !== "";
        }
    }
};
</script>

<style lang="scss" scoped>
.antes-input {
    position: absolute;
    text-align: center;
    color: #ffffff;
    margin-left: 12px !important;
    cursor: text;
}

.barra-pesquisa {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    color: #ffffff;

    .campo-texto {
        display: block;
        float: left;
        box-sizing: border-box;
        width: calc(100% - 45px);
        height: 100%;
        border-radius: 20px 0px 0px 20px;
        padding: 0px 20px;
        background: transparent;
        outline: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &::placeholder {
            font-style: italic;
            color: #ffffff;
        }
    }

    .excluir {
        padding-top: 15px;
        color: #bebebe;
        cursor: pointer;
    }

    .search {
        padding-top: 13px;
        color: #00995d;
        cursor: pointer;
    }
}
</style>
