<template>
    <div>
        <unicom-linha class="linha-cartoes">
            <unicom-coluna :md="12">
                <unicom-icone nome="monitor" :tamanho="22" textoComIcone class="icone" />
                <p class="fonte-secundaria negrito texto-ultimo-acesso ">{{ tipoListagem }} ({{ qtResultados }})</p>
                <div v-for="(aplicacao, index) in aplicacoes" :key="index" class="aplicacoes">
                    <CartaoAplicacao
                        :nomeDaAplicacao="aplicacao.nome"
                        :descricao="aplicacao.descricao"
                        @acessar="acessarAplicacao(index)"
                    />
                </div>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha
            :espacamento="false"
            class="linha-rodape"
            :justificarItem="'center'"
            v-if="aplicacoes.length <= 0"
        >
            <unicom-linha :md="3">
                <p class="fonte-secundaria texto">Nenhuma dado encontrado</p>
            </unicom-linha>
        </unicom-linha>
        <unicom-linha :espacamento="false" class="linha-rodape" v-if="todasAplicacoes">
            <unicom-coluna :md="2" class="coluna-exibicao">
                <small class="fonte-secundaria pequeno">
                    Exibindo {{ mostrandoLength }} de {{ rowsLength }} aplicações.
                </small>
            </unicom-coluna>
            <unicom-coluna :md="10">
                <unicom-linha :espacamento="false" :justificarItem="'end'">
                    <unicom-coluna :md="4" class="coluna-paginacao">
                        <div class="linha-paginacao">
                            <button class="botao-paginacao" :disabled="paginaAtual === 1" @click="trocaPagina(1)">
                                <unicom-icone :nome="'chevrons-left'" :tamanho="15" />
                            </button>
                            <button
                                class="botao-paginacao"
                                :disabled="paginaAtual === 1"
                                @click="trocaPagina(paginaAtual - 1)"
                            >
                                <unicom-icone :nome="'chevron-left'" :tamanho="15" />
                            </button>

                            <div
                                class="botao-paginacao"
                                v-for="(pagina, index) in numeroPaginas()"
                                :key="index"
                                :class="`${pagina === paginaAtual ? 'ativo' : ''}`"
                                @click="trocaPagina(pagina)"
                            >
                                <small class="fonte-secundaria pequeno">{{ pagina }}</small>
                            </div>

                            <button
                                class="botao-paginacao"
                                :disabled="paginaAtual === qtPaginas || qtPaginas === 0"
                                @click="trocaPagina(paginaAtual + 1)"
                            >
                                <unicom-icone :nome="'chevron-right'" :tamanho="15" />
                            </button>
                            <button
                                class="botao-paginacao"
                                :disabled="paginaAtual === qtPaginas || qtPaginas === 0"
                                @click="trocaPagina(qtPaginas)"
                            >
                                <unicom-icone :nome="'chevrons-right'" :tamanho="15" />
                            </button>
                        </div>
                    </unicom-coluna>
                </unicom-linha>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import CartaoAplicacao from "./CartaoAplicacao";

export default {
    components: {
        CartaoAplicacao
    },

    props: {
        aplicacoes: {
            type: Array,
            required: true
        },
        qtPaginas: {
            type: [String, Number],
            required: false,
            default: 1,
            note: "Define a quantidade total de paginas"
        },
        qtResultados: {
            type: [String, Number],
            required: false,
            default: 0,
            note: "Define quantos itens estão sendo exibidos na página"
        },
        pagina: {
            type: [String, Number],
            required: false,
            default: 1,
            note: "Define a página que será exibida"
        },
        todasAplicacoes: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            paginaAtual: this.pagina
        };
    },

    computed: {
        mostrandoLength() {
            return this.aplicacoes.length < 10 ? `0${this.aplicacoes.length}` : this.aplicacoes.length;
        },
        rowsLength() {
            return this.qtResultados < 10 ? `0${this.qtResultados}` : this.qtResultados;
        },
        tipoListagem() {
            return this.todasAplicacoes ? "Todas as aplicaçōes" : "Últimos acessos";
        }
    },

    methods: {
        numeroPaginas() {
            let paginas = [];
            let index = this.paginaAtual;
            if (this.qtPaginas === 0) return [1];

            if (this.qtPaginas === 1) {
                paginas = [index];
            } else if (index === 1 && this.qtPaginas === 2) {
                paginas = [index, index + 1];
            } else if (index > 1 && this.qtPaginas === 2) {
                paginas = [index - 1, index];
            } else if (index > 0 && index + 1 < this.qtPaginas) {
                paginas = [index, index + 1, index + 2];
            } else if (!(index === this.qtPaginas) && this.qtPaginas - index < 2) {
                paginas = [index - 1, index, index + 1];
            } else {
                paginas = [index - 2, index - 1, index];
            }

            return paginas;
        },
        trocaPagina(pagina) {
            if (this.paginaAtual !== pagina) {
                this.paginaAtual = pagina;
                this.$emit("troca-pagina", this.paginaAtual);
            }
        },
        acessarAplicacao(index) {
            this.$emit("acessar-aplicacao", this.aplicacoes[index]);
        }
    },

    watch: {
        pagina() {
            this.paginaAtual = this.pagina;
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-cartoes {
    margin-top: 16px;
    margin-bottom: 16px;
}

.aplicacoes {
    margin-left: 6px;
}

.icone {
    color: #60656c;
    margin-left: 6px;
}

.texto-ultimo-acesso {
    margin-left: 8px;
    color: #60656c;
    display: inline-block;
}

.linha-rodape {
    height: 40px;
    color: #60656c;
    margin-top: 10px;

    & .coluna-exibicao {
        height: 40px;

        & small {
            display: block;
            margin-top: 10px;
            padding-left: 4px;
        }
    }

    & .coluna-paginacao {
        height: 40px;
        padding-right: 11px;

        .linha-paginacao {
            display: block;
            float: right;

            .botao-paginacao {
                display: block;
                float: left;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin: 2.5px;
                border: 1px solid #bebebe;
                border-radius: 8px;
                padding-top: 2px;
                padding-right: 2px;
                background-color: #ffffff;
                color: #bebebe;
                cursor: pointer;
                text-align: center;

                & small {
                    display: inline-block;
                    margin-top: 4px;
                    margin-left: 1px;
                }

                &:disabled {
                    border: 1px solid #dee2e6;
                    color: #dee2e6;
                    cursor: not-allowed;
                }

                &.ativo {
                    color: #fff;
                    background-color: #00995d;
                    border: 1px solid #00995d;
                }
            }
        }
    }
}
</style>
