import Axios from "axios";

var AplicacoesServiceMixin = {
    methods: {
        getUltimasAcessadas() {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/aplicacoes/listar-aplicacoes-ultimas-acessadas"
                }),
                { withCredentials: true }
            );
        },
        getExcetoUltimasAcessadas(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/aplicacoes/listar-aplicacoes-exceto-ultimas",
                    params: args
                }),
                { withCredentials: true }
            );
        }
    }
};

export default AplicacoesServiceMixin;
