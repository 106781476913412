import Axios from "axios";
import { replace } from "lodash";
import 'whatwg-fetch'

export default class Redirect {
    constructor(url) {
        this.url = `${url}`;
    }

    fetchRedirect(args, idUnimed) {
        const myHeaders = new Headers({
            Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
            "X-UNIMED-APP": idUnimed
        });

        const url = replace(`${this.url}/api/no-cors/auth?${args}`, "#", "%23");

        return Axios.get(url, { headers: myHeaders })
    }

    /*

    fetchRedirect(args, idUnimed) {
        let myHeaders = new Headers({
            Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
            "X-UNIMED-APP": idUnimed
        });
        fetch(`${this.url}/api/auth?${args}`, {
            mode: "cors",
            //redirect: "follow",
            headers: myHeaders,
            credentials: "include"
        })
            .then(v => {
                console.log("Entrou")
                console.log(v)
                window.open('https://novacmbqa.unimed.coop.br/idp/sso/RNU-2618eb48-b497-4aaf-9ac9-1?tokenSSO=Kp80mHxJtNQmtx2KBVwz&code=Kp80mHxJtNQmtx2KBVwz&state=codigoUnimed%3D999%26userId%3D-1%26null');
            })
            .catch(error => console.error(error));
    }
    */
}
